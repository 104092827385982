import React from 'react'

import '../styles/pages/jobs-details.scss'

const JobsDetails = () => {
  return (
    <div className='jobs-container'>
        <h2>Back-End Developer Job Description</h2>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>About us</h4>
            <p>Risklick is a spin-off from the University of Bern, Switzerland, that is revolutionizing the development of clinical trial protocols using artificial intelligence. The company is developing the first of its kind, AI-based clinical trial protocol tool (co-pilot trial protocol) that enables experts to generate high-quality protocols in a short time. Risklick currently provides customer-centric solutions to several international organizations such as the World Health Organization (WHO), other national regulatory agencies, and pharmaceutical companies. We are shaping the future of clinical trial protocols with the aim of bringing new medications to patients faster and at a lower cost</p>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>About the role and you</h4>
            <p>As a PHP Developer with a Back-End focus, you, together with our international team, will be responsible for developing the server side of our web applications, creating, and integrating APIs, to optimizing database structures. You will work closely with the head of the back-end development team and collaborate with front-end developers to deliver a seamless user experience. You'll also be expected to document bugs, errors, and their corresponding solutions, contributing to a robust knowledge base for the team.
            </p>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>Expertise and skills</h4>
            <ul>
                <li>2+ years of experience with PHP (8.1 or newer)/Laravel (v8/9) in developing, designing, and maintaining high-quality server-side apps</li>
                <li>Creating and integrating APIs with Laravel to ensure seamless data transfer between front-end and back-end applications.</li>
                <li>Obeying clean code principles and best practices</li>
            </ul>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>Bonus points if you</h4>
            <ul>
                <li>Have knowledge or experience with Docker in a local environment</li>
                <li>Have experience with MariaDB, ORMs(Eloquent) and NoSQL databases (Redis)</li>
                <li>Have experience with development tools like Swagger and Postman to design, test, and document APIs</li>
                <li>Have experience with Unit testing (TDD, Functional & Integrations tests)</li>
            </ul>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>Education</h4>
            <p>Bachelor’s Degree (B.Sc.) of Informatics and computer engineering or similar is considered as a plus</p>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>Foreign languages</h4>
            <p>Fluent English is required, B2 / C1 preferred</p>
        </div>
        <div className='jobs-info'>
            <h4 className='jobs-info-title'>What do we offer?</h4>
            <ul>
                <li>25 vacation days</li>
                <li>Competitive salary</li>
                <li>Work on revolutionary solutions that will change the way clinical trial protocols are generated in the future and help patients waiting for a cure get access to new treatments faster and at a lower cost</li>
                <li>You’ll be able to use your creativity and technical skills to build and scale something that doesn’t exist and have the opportunity to learn from a multidisciplinary team of data scientists, medical experts, clinical trial specialists, and software developers</li>
                <li>You can learn state-of-the-art technology and upgrade your knowledge by working with the team and partner</li>
            </ul>
        </div>
    </div>
  )
}

export default JobsDetails